import { application } from "./application";

import DateInputController from "./date_input_controller";
import DropdownInputController from "./dropdown_input_controller";
import PasswordInputController from "./password_input_controller";
import SearchInputController from "./search_input_controller";
import SidebarController from "./sidebar_controller";
import ModalController from "./modal_controller";
import ApplyTopUpController from "./apply_top_up_controller";
import EnableButtonController from "./enable_button_controller";
import ApproveRejectController from "./approve_reject_controller";

application.register("date-input", DateInputController);
application.register("dropdown-input", DropdownInputController);
application.register("password-input", PasswordInputController);
application.register("search-input", SearchInputController);
application.register("sidebar", SidebarController);
application.register("modal", ModalController);
application.register("apply-top-up", ApplyTopUpController);
application.register("enable-button", EnableButtonController);
application.register("approve-reject", ApproveRejectController);
